<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/produtos-servicos">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/produtos-servicos"
                :fecharToastAutomaticamente="false"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="produtos-servicos-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="grid">
                        <div class="field col-8">
                            <label for="nome">Nome</label>
                            <InputText id="nome" v-model.trim="form.nome" :class="{ 'p-invalid': submitted && !form.nome }" />
                            <small class="p-error" v-if="submitted && !form.nome"> Nome é obrigatório. </small>
                        </div>

                        <div class="field col-4">
                            <label for="periodicidadeRenovacao">Periodicidade de Renovação (em meses)</label>
                            <InputNumber
                                id="periodicidadeRenovacao"
                                v-model="form.periodicidadeRenovacao"
                                :class="{ 'p-invalid': submitted && !form.periodicidadeRenovacao }"
                            >
                            </InputNumber>
                            <small class="p-error" v-if="submitted && !form.periodicidadeRenovacao">
                                Periodicidade de renovação é obrigatório.
                            </small>
                        </div>

                        <div class="field col-6">
                            <label for="tipoProdutoServico">Tipo</label>
                            <Dropdown
                                id="tipoProdutoServico"
                                v-model="form.tipoInventario"
                                :options="tiposProdutoServico"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Selecione..."
                            />
                        </div>

                        <div class="field col-6">
                            <label for="classificacao">Classificação</label>
                            <Dropdown
                                id="classificacao"
                                v-model="form.classificacao"
                                :options="tiposClassificacao"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Selecione..."
                                :class="{ 'p-invalid': submitted && !form.classificacao }"
                            />
                            <small class="p-error" v-if="submitted && !form.classificacao"> Classificação é obrigatório. </small>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';
import TipoProdutoServicoEnum from '../../enums/TipoProdutoServicoEnum';
import ClassificaoTipoServicoEnum from '../../enums/ClassificaoTipoServicoEnum';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            form: {
                ativo: true
            },
            service: null,
            submitted: false,
            tiposProdutoServico: [],
            tiposClassificacao: ClassificaoTipoServicoEnum
        };
    },
    created() {
        this.service = new BaseService('/produtos-servicos');
    },
    mounted() {
        this.getTiposProdutoServico();
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar Produto/Serviço' : 'Adicionar Produto/Serviço';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        getTiposProdutoServico() {
            this.tiposProdutoServico = Object.keys(TipoProdutoServicoEnum).map(function (key) {
                return { label: `${TipoProdutoServicoEnum[key]}`, value: `${key}` };
            });
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                this.$toast.add({ severity: 'error', summary: 'Há campos obrigatórios não preenchidos', life: 3000 });
                return cbError();
            }
            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return true;
            if (!this.form.periodicidadeRenovacao) return true;
            if (!this.form.classificacao) return true;
        }
    },
    components: { AppInfoManual }
};
</script>